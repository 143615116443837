import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../components/hero/Hero";
import Index from "../components/about/index";
import Address from "../components/Address";
import Portfolio from "../components/portfolio/Portfolio";
import Contact from "../components/Contact";
import Social from "../components/Social";
import SwitchDark from "../components/switch/SwitchDark";
import ReactGA from "react-ga4";

const menuItem = [
  { icon: "fa-home", menuName: "Home" },
  { icon: "fa-user", menuName: "About" },
  { icon: "fa-briefcase", menuName: "Portfolio" },
  { icon: "fa-envelope-open", menuName: "Contact" },
];

const HomeDark = () => {
  const forceScrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 150);
  };

  const handleNavigation = (id) => {
    const index = id.slice(-1);

    if (index) {
      ReactGA.event({
        category: "NavTab",
        action: "navigation tab clicked",
        label: menuItem[index].menuName,
      });
    }

    forceScrollToTop();
  };

  return (
    <div className="yellow">
      <SwitchDark />
      <Tabs>
        <div className="header">
          <TabList
            className=" icon-menu  revealator-slideup revealator-once revealator-delay1"
            onClick={(e) => handleNavigation(e.target.id)}
          >
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>

        <div className="tab-panel_list">
          <TabPanel className="home">
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="color-block d-none d-lg-block"></div>
              <Hero />
            </div>
          </TabPanel>

          <TabPanel className="about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  about <span>me</span>
                </h1>
                <span className="title-bg">Resume</span>
              </div>
              <Index />
            </div>
          </TabPanel>

          <TabPanel className="portfolio professional">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                my <span>portfolio</span>
              </h1>
              <span className="title-bg">projects</span>
            </div>
            <Portfolio />
          </TabPanel>

          <TabPanel className="contact">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                get in <span>touch</span>
              </h1>
              <span className="title-bg">contact</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row">
                <div className="col-12 col-lg-4">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                    Don't be shy!
                  </h3>
                  <p className="open-sans-font mb-4">
                    I am always interested in hearing about new projects,
                    opportunites, or ideas to create something special.
                  </p>
                  <Address />

                  <Social />
                </div>
                <div className="col-12 col-lg-8">
                  <Contact />
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default HomeDark;
