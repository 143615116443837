import React from "react";

const educationContent = [
  {
    year: "Expected May 2026",
    degree: "M.S Computer Science",
    institute: "University of Texas at Austin",
    details: `Relevant coursework includes: Deep Learning, Machine Learning, Automated Logical Reasoning, Natural Language Processing, Optimization, and Parallel Systems.`,
  },
  {
    year: "Dec 2023",
    degree: "B.S Computer Science",
    institute: "Colorado State University Global",
    details: `Relevant coursework includes: Software Engineering, Data Structures & Algorithms, Operating Systems & Architecture, and Logic & Design.`,
  },
  {
    year: "2021",
    degree: "Certification",
    institute: "FreeCodeCamp",
    details: `JavaScript Algorithms and Data Structures`,
  },
  {
    year: "2021",
    degree: "Certification",
    institute: "FreeCodeCamp",
    details: `Front End Development Libraries and Responsive Web Design`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
