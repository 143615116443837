import tiktik from "../../assets/img/portfolio/tik-tik.png";
import gameJam from "../../assets/img/portfolio/jamGameJam.png";
import terminal from "../../assets/img/portfolio/terminalD.png";
import bugLogger from "../../assets/img/portfolio/bugLogger.png";
import coachFinder from "../../assets/img/portfolio/coachFinder.png";
import islandAdventure from "../../assets/img/portfolio/islandAdventure.png";
import crowdCash from "../../assets/img/portfolio/crowdCash.png";

const PortfolioData = [
  {
    id: 1,
    type: "Crowd Cash",
    image: crowdCash,
    tag: ["react", "other"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Crowd Cash",
        github: "https://github.com/jamesonarnett/CrowdCash",
        tools: "React, Laravel, Tailwind",
        preview: "Vote now!",
        link: "https://crowdcash.ajameson.dev/",
        description:
          "Make your case, buy votes, and support community members in need! I made CrowdCash as a capstone project for school, and while the idea is not something I plan on pursuing long term, " +
          " considering there are plenty of crowd funding options already, it was an amazing dive into making a project using Laravel." +
          " From unit testing to deployment, this one was fun, and Laravel makes it a breeze!",
      },
    ],
  },
  {
    id: 2,
    type: "Island Adventure",
    image: islandAdventure,
    tag: ["vanilla", "game"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Island Adventure",
        github: "https://github.com/jamesonarnett/islandAdventure",
        tools: "JavaScript, HTML, CSS",
        preview: "Gotta catch them both!",
        link: "https://jamesonarnett.github.io/IslandAdventure/",
        description:
          "I built this project after getting interested in taking a look at Lua, for some possible Roblox game development." +
          " This is one of the few projects I've made where I took a strict Object Oriented approach, and have enjoyed the change of pace." +
          " What started out as learning Tiled and maybe making a game, turned into a project I am rather proud of!",
      },
    ],
  },
  {
    id: 3,
    type: "Terminal Emulator",
    image: terminal,
    tag: ["vue"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "JShell",
        github: "https://github.com/jamesonarnett/terminal",
        tools: "Vue.js, SCSS",
        preview: "github.terminal",
        link: "https://jamesonarnett.github.io/terminal/",
        description:
          "I built this project kinda just for fun. I had taken a course on Vue.js around the beginning of my internship at Spear," +
          " and I wanted to see if I could build something with it. While I am rather partial to React" +
          " I would not mind working with Vue again in the future, and am considering taking a better look at Nuxt next.",
      },
    ],
  },
  {
    id: 4,
    type: "TikTok Clone",
    image: tiktik,
    tag: ["react"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "TikTik",
        github: "https://github.com/jamesonarnett/TikTik",
        tools: "Next.js, TypeScript, Tailwind",
        preview: "tik-tik.vercel.app",
        link: "https://tik-tik-two.vercel.app/",
        description:
          "A social media app that allows users to create, share, and view short videos." +
          " I built this project to learn more about Next.js and Tailwind CSS, and while I am now very fond of Next, perhaps Tailwind just isnt for me." +
          " I just dont find it as intuitive as I would like, and I feel like keeping styling separate from the markup is just simpler.",
      },
    ],
  },
  {
    id: 5,
    type: "Game Jam",
    image: gameJam,
    tag: ["vanilla"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "JamGameJam",
        github: "https://github.com/jamesonarnett/JamGameJam",
        tools: " HTML, CSS, Javascript",
        preview: "gameJam",
        link: "https://jamesonarnett.github.io/JamGameJam/",
        description:
          'A "game jam" website built with no framework, just regular ole HTML and JavaScript.' +
          " I built this as one of my very first solo projects, over three years ago at this point, however still include it with" +
          " the newer projects here because I will always be proud of it. I was absolutely hooked at this point. :)",
      },
    ],
  },
  {
    id: 6,
    type: "Electron Bug Tracker",
    image: bugLogger,
    tag: ["electron", "react", "other"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Electron Bug Logger",
        github: "https://github.com/jamesonarnett/bugLogger",
        tools: "Electron, React, MongoDB",
        preview: "Download the App!",
        link: "https://github.com/jamesonarnett/bugLogger",
        description:
          "I built this project to learn more about Electron, and to get a better understanding of how to build desktop applications." +
          " A very pleasant surprise! It was incredibly easy to integrate React into the project, and I am very happy with the result.",
      },
    ],
  },
  {
    id: 7,
    type: "Coach Finder",
    image: coachFinder,
    tag: ["vue"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Coach Finder",
        github:
          "https://github.com/jamesonarnett/coachFinder/tree/gh-pages/coachFinder",
        tools: "Vue, VueX, SCSS",
        preview: "Find a Coach!",
        link: "https://jamesonarnett.github.io/coachFinder",
        description:
          "I built this project to learn more about Vue, and to gain some experience using VueX." +
          " I found using VueX's mutations, in place of Redux's reducers and immutable state, interesting and perhaps more useful depending on your architecture," +
          " and Vue to be very capable and incredibly intuitive. While it is not a particularly beautiful design" +
          " I am very happy with the functionality and overall result.",
      },
    ],
  },
];

export default PortfolioData;
