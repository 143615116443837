import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Location</span>Lake Charles, LA
      </p>

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">Email me</span>{" "}
        <a href="mailto:steve@mail.com">jamesonarnett@yahoo.com</a>
      </p>

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">Call me</span>{" "}
        <a href="Tel: +1 254-433-2285">+1 254-433-2285</a>
      </p>
    </>
  );
};

export default Address;
