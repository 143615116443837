import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Jameson" },
  { meta: "Nationality", metaInfo: "American" },
  { meta: "middle name", metaInfo: "Charles" },
  { meta: "Age", metaInfo: "Twenty-seven" },
  { meta: "Phone", metaInfo: "+1 254-433-2285" },
  { meta: "Food", metaInfo: "Anything wrapped in a tortilla" },
  { meta: "Email", metaInfo: "jamesonarnett@yahoo.com" },
  { meta: "Disposition", metaInfo: "Enthusiastic" },
  { meta: "Github", metaInfo: "jamesonarnett" },
];

const PersonalInfo = () => {
  return (
    <>
      <ul className="about-list list-unstyled open-sans-font">
        {personalInfoContent.map((val, i) => (
          <li key={i}>
            <span className="title">
              {val.meta !== "" ? `${val.meta}:` : ""}{" "}
            </span>
            <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
              {val.metaInfo === "jamesonarnett" ? (
                <a
                  href="https://github.com/jamesonarnett"
                  target="_blank"
                  className="info-github-link"
                >
                  jamesonarnett
                </a>
              ) : (
                val.metaInfo
              )}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default PersonalInfo;
