import React from "react";

const experienceContent = [
  {
    year: "Jan 2023 - Current",
    position: "Full Stack Developer",
    companyName: "Spear Education",
    details: `
    Customized Flarum open-source forum software to create a dentistry-focused social networking platform, executing 90% of frontend modifications to enable professional networking and knowledge sharing. 
    Developed responsive and accessible user interfaces using modern JavaScript frameworks, increasing mobile user engagement and improving accessibility. 
    Provided crucial support in maintaining and upgrading legacy PHP micro-sites built with Laravel and Zend, focusing on performance optimization and code modernization. 
    Actively participated in Agile development processes, contributing to consistent on-time sprint deliveries and improved team efficiency. 
    `,
  },
  {
    year: "May 2022 - September 2022",
    position: "Full Stack Developer Intern",
    companyName: "Spear Education",
    details: `
    Collaborated on developing a new user dashboard, focusing on churn reduction and increased engagement metrics. 
    Implemented unified static 404 pages across frontend microservices using AWS S3 in partnership with UX team and senior developers, enhancing overall user experience consistency. 
    `,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.companyName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
